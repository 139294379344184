@import 'stylesheets/theme.scss';

.container {
  height: 100%;
  width: 100%;
}
.containerNotFound {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.notfound {
  color: $app-tertiary-text;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 100px;
}
