@import 'stylesheets/theme.scss';

.container {
  background-color: $app-primary-background;
  height: 100%;
  width: 100%;
}
.bottomSection {
  height: calc(100% - 60px);
  padding: 10px;
  overflow: auto;
}
.wrapCon {
  display: flex;
  flex-wrap: wrap;
}
