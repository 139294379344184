@import 'stylesheets/theme.scss';
.emoji {
    border-radius: 50%;
    padding: 10px;
}
.categoryEmojis {
    overflow: auto;
    height: 300px;
    padding: 0 10px 15px;
}
.emojiPickerContainer {
    border: solid 1px $app-primary-bg-border;
    border-radius: 5px;
    width: 380px;
}
.emojiTabsContainer {
    border-bottom: solid 1px $app-primary-bg-border;
}
.filterEmoji {
    width: 100%;
}
.filterEmoji input {
    font-size: 12px;
    height: 0;
}
.searchBar {
    border: solid 1px $app-primary-bg-border;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
    padding: 8px;
    padding-left: 30px;
    outline: none;
}