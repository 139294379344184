$app-primary-text: #0e4b7f;
$app-secondary-text: #ffffff;
$app-tertiary-text: #4c5563;

$app-primary-bg-text: #979797;
$app-primary-background: #f3f4f6;
$app-secondary-background: #fcfcfc;
$app-primary-error: #ff0000;

$app-pure-black: #000000;
$app-nav-gtext: #4c5563;
$app-userbar-bg: #f5f7f9;
$app-primary-button-text: #1976d2;
$app-pure-white: #ffffff;

$app-primary-bg-border: #cfd7df;

$app-line-break: #e2e6e9;
$app-scroll-thumb: #cccccc;