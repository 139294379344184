@import 'stylesheets/theme.scss';
.emojiInputContainer {
    border: solid 1px #cfd7df;
    border-radius: 5px;
    width: 47px;
    height: 45px;
    display: inline-block;
    padding: 5px;
    cursor: pointer;   
}
.emojiContainer {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 3px;
    margin-bottom: -4px;
    cursor: pointer;
    color: $app-tertiary-text;

    input {
        width: 1px;
        height: 1px;
        outline: none;
        font-size: 0;
        border: 0;
    }
}