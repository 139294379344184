@import 'stylesheets/theme.scss';

.container {
  background-color: $app-secondary-background;
  height: 100%;
  padding: 5px 25px;
  width: 100%;
}
.appHeaderCon {
  background-color: $app-primary-text;
  border-radius: 6px;
  align-items: center;
  height: 55px;
  padding: 0 20px;
  display: flex;
  margin-bottom: 27px;
  user-select: none;
}
.appLogo {
  height: 40px;
  width: 40px;
}
.appTitle {
  color: $app-primary-background;
  font-size: 2.2rem;
  font-weight: bold;
  margin-left: 15px;
  padding-top: 5px;
}
.naviContainer {
  height: 80%;
  overflow: auto;
}
//$app-nav-gtext
.groupHeaderCon {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
}
.categoryLogo {
  fill: $app-primary-text;
  height: 10px;
  margin-right: 6px;
}
.categoryLogoOpen {
  transform: rotate(90deg);
}
.groupHeaderText {
  color: $app-primary-text;
  font-size: 1.5rem;
  font-weight: 600;
  user-select: none;
}
.linkChannelsCon {
  margin-bottom: 5px;
  overflow: hidden;
}
.navLink {
  color: $app-nav-gtext;
  font-size: 1.4rem;
  font-weight: 400;
  display: block;
  padding: 7.5px 0px 0px 16px;
  text-decoration: none;
  height: 35px;
}
.navLink:hover {
  font-weight: bold;
}
.navLinkActive {
  background-color: $app-primary-background;
  border-radius: 5px;
  font-weight: bold;
}
.helpLinkContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}
.helpLink {
  color: $app-primary-text;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
}
.linkLogo {
  height: 15px;
  margin-left: 10px;
}
