@import 'stylesheets/theme.scss';
.reportCardContainer {
    margin-top: 20px;
    color: $app-tertiary-text;
    font-size: 1.5rem !important;
    font-weight: 300 !important;
    overflow: auto;
}
.userReportsContainer {
    height: 500px;
    background-color: $app-pure-white;
}
.categoryReportsContainer {
    margin-top: 30px;
    background-color: $app-pure-white;
    width: 500px;
}
.categoryReportsWrapper {
    background-color: $app-pure-white;
}
.categoryHeader {
    padding: 30px ;
}
.noReports {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.userInfo {
    display: flex;
    align-items: center;
}
.reviews {
    font-size: 1.2rem;
    text-transform: none;
}
.hidden {
    visibility: hidden;
}