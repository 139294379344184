@import 'stylesheets/theme.scss';

.container {
  align-items: center;
  display: flex;
  position: relative;
}
.searchBar {
  border: 1px solid $app-primary-text;
  border-radius: 30px;
  font-size: 1.4rem;
  color: $app-tertiary-text;
  background-position: 10px 7px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 33px;
  outline: none;
  height: 28px;
  width: 180px;
}
.searchLogo {
  position: absolute;
  height: 15px;
  width: 15px;
  left: 10px;
  top: 8px;
}
