@import 'stylesheets/theme.scss';

.container {
    background-color: $app-pure-white;
    height: 100%;
    width: 100%;
  }
.close {
  display: flex;
  color: $app-tertiary-text;
  padding: 10px;
  font-size: 12px;
}
.close button {
  text-transform: capitalize !important;
  color: $app-tertiary-text !important;
}
.settingsTab {
  padding: 30px !important;
  text-transform: capitalize !important;
}
.userContainer {
  color: $app-tertiary-text;
  width: 550px;
  min-height: 250px;
  border: solid 1px $app-primary-bg-border;
  border-radius: 10px;
  padding: 30px 0 30px 20px !important;
  &[is-admin='false'] {
    width: 400px;
  }
}

.userListContainer {
  max-height: 400px;
  overflow: auto;
}
.addUsersContainer {
  div[role='combobox'] {
    padding-top: 10px;
    min-width: 250px;
    width: 400px;
    max-height: 500px;
    overflow: auto;
    input {
      font-size: 1.4rem;
    }
    label[id='add-users-label'] {
      font-size: 1.6rem;
    }
    label[id='add-users-label'][data-shrink=false] {
      margin: 10px;
    }
    label[id='add-users-label'] + div {
      padding: 20px;
    }
  }
}
.userOption {
  color: $app-tertiary-text;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  .userOptionText {
    display: inline-block;
  }
}
.userTag {
  span {
    font-size: 1.3rem;
    padding-left: 5px;
    color: $app-tertiary-text;
  }
}
.tabPanel {
  min-width: 400px;
  padding: 40px;
}
.settingsContainer {
  display: inline-block;
  width: 100%;
}
.settingsTabs {
  background-color: $app-primary-background;
  border-right: 1px;
  font-size: 25px;
  min-width: 125px;
  border-radius: 5px;
  text-transform: capitalize;
}
.adminLabel {
  width: 50px;
  margin: 0 35px 0 12px;
}
.adminActions {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.jiraSettingsEmojiSelector {
  display: flex;
  align-items: center;
  margin-right: 1%;
}
.removeUser {
  font-size: 1.8rem !important;
  cursor: pointer;
}
.elementsContainer {
  color: $app-tertiary-text;
  border: solid 1px $app-primary-bg-border;
  border-radius: 10px;
  padding: 20px 0 20px 30px !important;
  width: 500px;
  text-transform: capitalize;
  .elementList {
    min-height: 250px;
    max-height: 430px;
    overflow: auto;
  }
  .elementEmoji {
    width: 70px;
  }
  .elementName span {
    font-size: 1.4rem;
    font-weight: inherit;
    overflow: hidden;
    display:inline-block;
    text-overflow:ellipsis;
    width:100px;
  }
}
.addCategory {
  display: flex;
  align-items: center;
  color: $app-primary-button-text;
  justify-content: space-between;
  width: 125px;
  font-size: 1.3rem;
  margin: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}
.categoriesContainer {
  min-height: 250px;
  max-height: 350px;
  overflow: auto;
  margin-top: 20px !important
}
.categoryContainer{
  margin-bottom: 15px;
  .categoryName input {
    color: $app-tertiary-text; 
    font-size: 1.4rem;
    width: 140px;
    text-overflow: ellipsis;
  }
  .categoryName div {
    color: $app-tertiary-text; 
    font-size: 1.4rem;
    width: 100px;
    text-overflow: ellipsis;
  }
  .categoryName label {
    color: $app-tertiary-text; 
    font-size: 1.4rem;
    width: 75px;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
.removeCategory {
  font-size: 2rem !important;
  cursor: pointer;
}
.categoryEmoji {
  margin-right: 30px;
}
.userSearchContainer {
  margin-left: 70px;
  margin-bottom: 10px;
  .searchBar {
    font-size: 1.3rem;
    border: solid 1px $app-primary-bg-border;
    padding: 8px 30px;
    border-radius: 10px;
    outline: none;
  }
}
.dateSelectorCon {
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.dateLabelFrom,
.dateLabelTo {
  color: $app-tertiary-text;
  font-size: 1.3rem;
  font-weight: 400;
}
.dateLabelFrom {
  margin-right: 10px;
}
.dateLabelTo {
  margin-left: 10px;
  margin-right: 10px;
}
.errorMsgCon{
  margin-left:19ch;
  margin-top: 5px;
}
.errorMsg{
  color: red;
}

.loadingIconCon{
  width: 100%;
  display: flex;
  justify-content: center;
}
