@import 'stylesheets/theme.scss';

$app-card-link-bg: #f9fafb;
$app-card-link: #0e4b7f;

.container {
  border-radius: 5px;
  background-color: $app-secondary-text;
  box-shadow: 0 0px 3px 0px $app-primary-bg-border;
  height: 85px;
  margin: 10px;
  top: 0px;
  width: 130px;
}
.container:hover {
  box-shadow: 0 0px 5px 0px $app-primary-bg-border;
}
.infoView {
  height: 100%;
  padding: 15px 20px;
}
.headingLabel {
  color: $app-tertiary-text;
  font-size: 1.4rem;
  font-weight: 400;
}
.content {
  color: $app-pure-black;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 14px;
  display: inline-block;
}
.subtext {
  color: $app-tertiary-text;
  font-size: 1.3rem;
  margin-left: 5px;
}