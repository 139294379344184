@import 'stylesheets/theme.scss';

$date-picker-shades: rgba(0, 120, 250, 1);

.datePicker {
  background: white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
    right 1rem center no-repeat;
  border: 1px solid $app-primary-text;
  border-radius: 0.25rem;
  color: $app-tertiary-text;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  position: relative;
  padding: 10px 12px 12px 12px;
  width: 145px;
  height: 35px;
}
.datePicker:focus {
  outline: none;
}
::-webkit-datetime-edit-text {
  opacity: 0;
}
::-webkit-clear-button,
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  color: $date-picker-shades;
  background: $date-picker-shades;
}
