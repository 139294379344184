@import 'stylesheets/theme.scss';

.msgCardCon {
  background-color: $app-pure-white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
}
.nameCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftNameCon {
  align-items: center;
  display: flex;
}
.rightNameCon {
  align-items: center;
  display: flex;
}
.userAvator {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.userName {
  color: $app-tertiary-text;
  text-transform: capitalize;
  font-size: 1.4rem;
  margin-left: 10px;
  font-weight: 600;
}
.msgCon {
  padding-left: 40px;
  padding-top: 6px;
  overflow: auto;
  max-width: 70vw;
}
.userMsg {
  color: $app-tertiary-text;
  font-size: 1.3rem;
}
.ratingMsg {
  margin-right: 15px;
  margin-left: 5px;
}
.lastUpdatedCon {
  margin-right: 15px;
}
.creationTime {
  color: $app-tertiary-text;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 7px;
}
.slackLinkIcon {
  cursor: pointer;
  height: 16px;
  margin-left: 10px;
}
.userNameCircle {
  align-items: center;
  text-transform: capitalize;
  background-color: $app-primary-text;
  border-radius: 50%;
  color: $app-pure-white;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
}
