@import 'stylesheets/theme.scss';

.tagBoxCon {
  align-items: center;
  background-color: $app-primary-background;
  border-radius: 7px;
  color: $app-primary-bg-text;
  display: flex;
  font-weight: bold;
  height: 30px;
  padding: 5px 10px;
}
.tagTitle {
  font-size: 1.2rem;
  font-weight: bold;
}
.tagContent {
  color: $app-primary-text;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 5px;
  text-transform: capitalize;
}
