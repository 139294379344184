@import 'stylesheets/theme.scss';

.dropdown {
  border: 1px solid $app-primary-text;
  border-radius: 5px;
  color: $app-tertiary-text;
  font-size: 1.4rem;
  height: 28px;
  margin-left: 13px;
  margin-right: 13px;
  padding: 2px 6px;
}
