.container {
  display: flex;
  height: 100%;
  width: 100%;
}
.leftContainer {
  height: 100%;
  min-width: 260px;
  max-width: 350px;
  width: 25%;
}
.rightContainer {
  height: 100%;
  width: 100%;
}
.rightTopCon {
  height: 60px;
}
.rightBottomCon {
  height: calc(100% - 60px);
}
