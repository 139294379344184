@import 'stylesheets/theme.scss';

.container {
  background-color: $app-primary-background;
  height: 100%;
  width: 100%;
}
.topSection {
  display: flex;
  height: 60px;
  justify-content: space-between;
  border-bottom: 1px $app-line-break solid;
  padding: 10px 20px;
}
.bottomSection {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  padding: 10px 20px;
}
.dropCon {
  display: flex;
  align-items: center;
}
.dateSelectorCon {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.dateLabelFrom,
.dateLabelTo {
  color: $app-tertiary-text;
  font-size: 1.3rem;
  font-weight: 400;
}
.dateLabelFrom {
  margin-right: 10px;
}
.dateLabelTo {
  margin-left: 10px;
  margin-right: 10px;
}
.filterCon {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}
.metricsViewCon {
  padding-bottom: 10px;
}
.messagesViewCon {
  border-top: 1px $app-line-break solid;
  padding-top: 15px;
  height: 80%;
}
.messageCon {
  height: calc(100% - 50px);
  overflow: auto;
  padding-right: 10px;
}
.searchBarCon {
  margin-left: 10px;
}
.msgStatusCon {
  background-color: $app-primary-text;
  border-radius: 15px;
  color: $app-pure-white;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 5px 10px;
  margin-left: 15px;
  user-select: none;
}
