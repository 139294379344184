@import 'stylesheets/theme.scss';

.container {
  background-color: $app-primary-background;
  height: 100%;
  width: 100%;
}
.centreView {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.appLogo {
  height: 350px;
}
.titleContainer {
  margin-left: 50px;
}
.appTitle {
  color: $app-primary-text;
  font-size: 3.5rem;
  font-weight: bold;
  padding-bottom: 15px;
  letter-spacing: 1.5px;
}
.googleLoginBtn {
  border-radius: 6px !important;
}
.googleLoginBtn > span {
  margin-bottom: 5px;
}
.errorMsg {
  font-size: 1.5rem;
  color: $app-primary-error;
  height: 20px;
  padding-top: 25px;
  text-align: center;
}
