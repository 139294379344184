@import 'stylesheets/theme.scss';
.userAvatar {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}
.avatarContainer {
  display: inline-block;
}
.userNameCircle {
    align-items: center;
    text-transform: capitalize;
    background-color: $app-primary-text;
    border-radius: 50%;
    color: $app-pure-white;
    font-size: 1.6rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.emoji {
  font-family: none;
  font-size: inherit;
  line-height: 100%;
}