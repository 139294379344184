@import 'stylesheets/theme.scss';

.container {
  background-color: $app-primary-text;
  height: 100%;
  width: 100%;
}
.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;
}
.leftZone {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rightZone {
  align-items: center;
  display: flex;
}
.userName {
  color: $app-secondary-text;
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 12px;
  margin-right: 20px;
}
.userAvator {
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
.nameProfileCon {
  display: flex;
  align-items: center;
}
.logoutBtn {
  align-items: center;
  background-color: $app-primary-background;
  border: 1px solid transparent;
  border-radius: 6px;
  color: $app-primary-text;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: bold;
  height: 35px;
  padding-left: 15px;
  width: 80px;
}
.appLogo {
  height: 30px;
  margin-right: 12px;
}
.userNameCircle {
  align-items: center;
  text-transform: capitalize;
  background-color: $app-secondary-text;
  border-radius: 50%;
  color: $app-primary-text;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
}
