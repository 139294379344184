@import 'stylesheets/theme.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  overflow: hidden;
}
button {
  font-family: 'Inter', sans-serif;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: $app-scroll-thumb;
  border-radius: 10px;
}
